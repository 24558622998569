import * as React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';

export const Head = () => (
  <>
    <title>Promocje na zabiegi pielęgnacyjne, vouchery, karty i bony podarunkowe | Gabinet Elżbieta</title>
    <link rel="canonical" href="https://gabinetelzbieta.pl/o-gabinecie/pomysl-na-prezent/" />
  </>
);

const Main = styled.main`
  font-family: 'Lato', sans-serif;
`;

const Slider = styled.section`
  .gatsby-image-wrapper-constrained {
    width: 100%;
  }
`;

const PomyslNaPrezent = ({ data }) => (
  <>
    <Slider>
      <GatsbyImage
        image={getImage(data.hero_1)}
        alt="Gabinet Elżbieta"
        loading="eager"
      />
    </Slider>

    <Main>
      <div className="row">
        <h1>Pomysł na prezent</h1>

        <p style={{ margin: 0 }}>Nie masz pomysłu na prezent?</p>
        <p>Zachęcamy do zakupu kuponów podarunkowych lub zaproszeń na zabieg!</p>

        <p>Dla Naszych klientów przygotowaliśmy bony kwotowe, które mogą być świetnym prezentem. Można nimi płacić w&nbsp;Gabinecie Kosmetyki Leczniczej Elżbieta, tak samo jak gotówką.</p>

        <p style={{
          marginTop: '50px',
        }}
        >
          <strong>Zaproszenie do gabinetu kosmetyki</strong>

        </p>

        <p>Eleganckie zaproszenie na zabieg można u&nbsp;nas kupić za dowolną kwotę. Jest to&nbsp;oryginalny prezent dla kobiety, mężczyzny, przyjaciół, na dzień matki, pod choinkę. Zabiegi i&nbsp;dobór kosmetyków komponowany jest w&nbsp;zależności od potrzeb obdarowanego.</p>

        <figure style={{
          margin: '50px 0 0',
          textAlign: 'center',
        }}
        >
          <GatsbyImage
            image={getImage(data.voucher)}
            alt="Voucher Gabinet Elżbieta"
          />
          <figcaption>
            <strong>Voucher Podarunkowy</strong>
            <br />
            - doskonały pomysł na prezent
          </figcaption>
        </figure>
      </div>
    </Main>
  </>
);

export const query = graphql`
  query {
    hero_1: file(relativePath: {regex: "/hero\/1.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 2000
        )
      }
    },
    voucher: file(relativePath: {regex: "/gabinet\/voucher-elzbieta.jpeg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 500
        )
      }
    }
  }
`;

export default PomyslNaPrezent;
